<template>
  <div class="out-div">
    <!-- <div class="equ-main animated fadeInUp">
      <img class="test-img" src="@/assets/image/test/miaoqing.png" alt="" />
    </div> -->
    <div class="gather animated fadeInUp">
      <page-left
        :config="pageLeftConfig"
        :lists="lists"
        @changeItems="changeItems"
        @changeAll="changeAll"
        @loadMoreVideoData="loadMoreVideoData"
      ></page-left>
      <div class="right-video" v-show="!type">
        <iframe
          frameborder="0"
          scrolling="no"
          :src="videoUrl"
          name="video"
          style="width: 100%; height: 70vh"
        ></iframe>
        <div class="row-other">
          <span class="now-video" @click="getNowVideo">实时画面</span>
          <span class="history-video" @click="getHistoryVideo"> 历史回放</span>
          <ul class="video-iframe-ul">
            <li
              class="video-iframe-li"
              v-for="(item, index) in videoMoveList"
              :key="index"
              @mousedown="blowUp(item.direction)"
              @mouseup="zoomOut(item.direction)"
            >
              {{ item.title }}
            </li>
          </ul>
        </div>
      </div>
      <div class="videoList" v-if="type">
        <div class="videoItems" v-for="t in lists" :key="t.id">
          <iframe
            frameborder="0"
            name="video"
            scrolling="no"
            width="410"
            height="300"
            style="margin: 0 10px 10px 0"
            :src="
              'https://hs.darsing.net/project/digital2/video/address?eid=' +
              t.id +
              '&return_type=iframe'
            "
          ></iframe>
          <p class="videoTit">{{ t.title }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import {
  getEquipmentGroupApi,
  getEquipmentListApi,
  getEquipmentInfoApi,
  postMoveVideoApi,
} from "@/request/api";

export default {
  components: {
    pageLeft: () => import("@/views/equipment/component/pageLeft.vue"),
  },
  data() {
    return {
      type: 0,
      changeIndex3: 0,
      sizeType: false,
      videoId: "",
      videoUrl: "",
      pageLeftConfig: {
        placeholder: "搜索视频设备名称（编号）",
        img: this.$imgOnlineUrl + "/equipment/icon1.png",
      },
      // 视频列表
      lists: [],
      // 分页
      videoPageNum: 1,
      videoPageSize: 10,
      videoCount: 0,
      videoMoveList: [
        {
          title: "光学放大",
          direction: 8,
        },
        {
          title: "光学缩小",
          direction: 9,
        },
      ],
    };
  },
  watch: {
    "$route.query": {
      handler(newVal) {
        this.channel_id = newVal.id;
        this.getEquipmentListApi();
      },
      immediate: true,
    },
  },
  created() {
    this.getEquipmentGroupApi();
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
    }),
  },
  methods: {
    ...mapMutations("user", ["SET_EQUIPMENT_Name"]),
    async getEquipmentGroupApi() {
      const { code, data } = await getEquipmentGroupApi();
      if (code === 200) {
        this.tabList3 = {
          0: data[0],
        };
        if (!sessionStorage.getItem("equipmentName")) {
          sessionStorage.setItem("equipmentId", data[0].id);
          sessionStorage.setItem("equipmentName", data[0].icon);
        }
        this.changeIndex3 = this.$route.query.id || this.tabList3["0"].id;
        this.channel_id = this.changeIndex3;
        this.getEquipmentListApi();
      }
      // if (!id) {
      //   this.changeTab3({ id: "", icon: "" });
      // }
    },
    async blowUp(direction) {
      await postMoveVideoApi({
        direction,
        project_id: 2,
        table_id: this.videoId,
        speed: 1,
        act: "start",
      });
    },
    async zoomOut(direction) {
      await postMoveVideoApi({
        direction,
        project_id: 2,
        table_id: this.videoId,
        speed: 1,
        act: "stop",
      });
    },
    loadMoreVideoData() {
      if (this.lists.length && this.lists.length == this.videoCount) return;
      this.videoPageNum++;
      this.getEquipmentListApi(this.videoPageNum);
    },
    // 加载全部视频
    changeAll(type) {
      this.type = type;
    },
    async getVideoAddressApi(id) {
      this.videoId = id;
      this.videoUrl = `https://hs.darsing.net/project/digital2/video/address?eid=${id}&return_type=iframe&template=pcLive`;
    },
    // 选中左侧视频
    changeItems({ id, type, sizeType }) {
      this.sizeType = sizeType;
      this.type = type;
      this.videoId = id;
      this.videoUrl = `https://hs.darsing.net/project/digital2/video/address?eid=${this.videoId}&return_type=iframe&template=pcLive`;
    },
    getNowVideo() {
      this.videoUrl = `https://hs.darsing.net/project/digital2/video/address?eid=${this.videoId}&return_type=iframe&template=pcLive`;
    },
    getHistoryVideo() {
      this.videoUrl = `https://hs.darsing.net/project/digital2/video/address?eid=${this.videoId}&return_type=iframe&template=pcRec`;
    },
    // 设备详情
    async getEquipmentInfoApi_status() {
      const { code, results } = await getEquipmentInfoApi({
        manor_id: this.farmID,
        table_id: this.lists[0].id,
        act: "status",
      });
      if (code === 0) {
        this.online = results.online;
      }
    },
    // 设备列表
    async getEquipmentListApi(pageNum = 1) {
      if (!this.channel_id) return;
      const { code, count, page_num, page_size, results } =
        await getEquipmentListApi({
          manor_id: this.farmID,
          channel_id: this.channel_id,
          page_num: pageNum,
          page_size: 20,
        });
      if (code === 0) {
        // this.lists = [results[0]];
        // this.lists = results;
        // this.lists.forEach((item) => {
        //   item.sizeType = item.title.indexOf("大") != -1 ? true : false;
        // });
        this.lists = results.slice(1);
        this.lists = this.lists.filter((item) => {
          return item.title.indexOf("大") != -1;
        });
        this.videoCount = count;
        this.videoPageNum = page_num;
        this.videoPageSize = page_size;
        this.getVideoAddressApi(this.lists[0].id);
      }
    },

    changeTab3({ id, icon }) {
      if (id) {
        this.changeIndex3 = id;
      }
      sessionStorage.setItem("childTitle3", this.changeIndex3);
      sessionStorage.setItem("equipmentId", id);
      sessionStorage.setItem("equipmentName", icon);
      this.SET_EQUIPMENT_Name({ icon });

      switch (icon) {
        case "records":
          this.$router.push({
            name: "records",
          });
          break;
        // case "weather":
        //   this.$router.push({
        //     name: "meteorological",
        //     query: { id },
        //   });
        //   break;

        case "video":
          this.$router.push({
            name: "grow",
            query: { id },
          });
          break;
        // case "water":
        //   this.$router.push({
        //     name: "chemical",
        //     query: { id },
        //   });
        //   break;
        // case "soil":
        //   this.$router.push({
        //     name: "chemical",
        //     query: { id },
        //   });
        //   break;
        // case "heat":
        //   this.$router.push({
        //     name: "chemical",
        //     query: { id },
        //   });
        //   break;
        // case "fertilizer":
        //   this.$router.push({
        //     name: "chemical",
        //     query: { id },
        //   });
        //   break;
        // case "feed":
        //   this.$router.push({
        //     name: "chemical",
        //     query: { id },
        //   });
        //   break;
        // case "control":
        //   this.$router.push({
        //     name: "chemical",
        //     query: { id },
        //   });
        //   break;
        // case "machine":
        //   this.$router.push({
        //     name: "chemical",
        //     query: { id },
        //   });
        //   break;
        // case "insect":
        //   this.$router.push({
        //     name: "chemical",
        //     query: { id },
        //   });
        //   break;
        // case "gate":
        //   this.$router.push({
        //     name: "chemical",
        //     query: { id },
        //   });
        //   break;
        // case "grow":
        //   this.$router.push({
        //     name: "chemical",
        //     query: { id },
        //   });
        //   break;
        // case "shuifei":
        //   this.$router.push({
        //     name: "chemical",
        //   });
        //   break;
        case "":
          this.$router.push({
            name: "grow",
            query: { id: this.changeIndex3 },
          });
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
@keyframes imgRotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(180deg);
  }
}
.out-div {
  padding: 0 48px;
  .gather {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #071a2c;
    padding: 0 0 24px;
    justify-content: space-between;
    position: relative;
    .videoList {
      width: 1300px;
      height: 800px;
      display: flex;
      flex-wrap: wrap;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 7px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #3e90e5;
      }
      .videoItems {
        width: 410px;
        margin: 0 10px 10px 0;

        .videoTit {
          width: 100%;
          font-size: 14px;
          padding: 0 10px;
          position: relative;
          &::before {
            content: "";
            width: 4px;
            height: 100%;
            border-radius: 2px;
            background-color: #3e90e5;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
    .right-video {
      width: 1300px;
      // width: calc(100% - 387px);
      height: 800px;
      background: #0e2138;
      display: flex;
      padding: 20px 53px 0;
      flex-direction: column;
      // justify-content: space-around;
      .video-box {
        width: 100%;
        height: 585px;
        border: 1px solid #000;
      }
      .control {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 53px;

        img {
          width: 22px;
          height: 22px;
          cursor: pointer;
        }

        span {
          font-size: 22px;
          margin: 0 25px;
        }
      }
      .row-other {
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        padding: 10px 0;

        // background-color: #fff;
        .now-video,
        .history-video {
          cursor: pointer;
          margin-right: 10px;
          font-size: 14px;

          &:hover {
            color: #3e90e5;
          }
        }
        .video-iframe-ul {
          display: flex;
          padding: 10px 0;
          .video-iframe-li {
            font-size: 14px;
            margin-right: 10px;
            cursor: pointer;
            &:hover {
              color: #3e90e5;
            }
          }
        }
      }
    }
  }
}
</style>
